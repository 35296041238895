import React from "react";
import { motion } from "framer-motion";
import * as ReactTooltip from "react-tooltip";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Skills.scss";
import { technologies, experiences } from "../../constants";

const Skills = () => {
  return (
    <>
      <h2 className="head-text">Skills & Experiences</h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {technologies.map((skill) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={skill.name}
            >
              <div className="app__flex" style={{ backgroundColor: "white" }}>
                <img src={skill.icon} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
        <div className="app__skills-exp">
          {experiences.map((experience) => (
            <>
              <motion.div
                className="app__skills-exp-item"
                key={experience.company_name}
              >
                <div className="app__skills-exp-year">
                  <p className="bold-text">{experience.date}</p>
                </div>
                <motion.div className="app__skills-exp-works">
                  <motion.div
                    whileInView={{ opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                    className="app__skills-exp-work"
                    data-tip
                    data-for={experience.title}
                  >
                    <h4 className="bold-text">{experience.title}</h4>
                    <p className="p-text">{experience.company_name}</p>
                  </motion.div>
                </motion.div>
              </motion.div>
              <motion.div
                id={experience.company_name}
                effect="solid"
                arrowColor="#fff"
                className="skills-tooltip"
              >
                {experience.points}
              </motion.div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, "app__skills"),
  "skills",
  "app__whitebg"
);
