import React from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./About.scss";

const About = () => {
  return (
    <>
      <h2 className="head-text">
        Here <span>introduce</span> <br />
        something <span>about me</span>
      </h2>

      <div className="app__profiles">
        <motion.div
          whileInView={{ opacity: 1 }}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.5, type: "tween" }}
          className="app__profile-item"
        >
          <h2 className="bold-text" style={{ marginTop: 20, fontSize: "24px" }}>
            Full Stack Developer | Software Engineer
          </h2>
          <p className="p-text" style={{ marginTop: 10, fontSize: "20px" }}>
            Working as a software engineer with 3+ years commercial experience
            and 5+ overrall experience in software development field. Passionate
            with solving complex problems and create innovative solutions,
            building SPA from scratch. Proficient in ReactJS(Next.js 13),
            experienced collaborating with cross-functional teams on a variety
            of projects. Communicating and delivering concepts to both tech and
            non-tech stakeholders. Greate individual worker or team player whose
            comfortable to agile management. Comfortable with fully remote
            working environment and good at time management and high working
            efficiency.
          </p>
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
