import React from "react";
import { BsGithub } from "react-icons/bs";

const SocialMedia = () => (
  <div className="app__social">
    {/* <div
      onClick={() =>
        window.open(
          "https://www.linkedin.com/in/caspar-cheng-945a661a5",
          "_blank"
        )
      }
    >
      <BsLinkedin />
    </div> */}
    <div onClick={() => window.open("https://github.com/Caspar2318", "_blank")}>
      <BsGithub />
    </div>
  </div>
);

export default SocialMedia;
