import {
  javascript,
  typescript,
  html,
  css,
  reactjs,
  redux,
  nodejs,
  mongodb,
  git,
  api,
  py,
  mui,
  qut,
  lightmare,
  accenture,
  casexpress,
  collection,
  xboard,
  youtube,
  fitness,
  chatgpt,
  reactNative1,
  cryptosearch,
  netflix,
  portfolio,
  shirt,
  airbnb,
  twitter,
  summarizer,
  nftgame,
  csharp,
  graphql,
  figma,
  aws,
  mysql,
  nextjs,
  uipath,
  aspnet,
  mbtlogo,
} from "../assets";

export const navLinks = [
  {
    id: "about",
    title: "About",
  },
  {
    id: "projects",
    title: "Projects",
  },
  {
    id: "work",
    title: "Experience",
  },
  {
    id: "contact",
    title: "Contact",
  },
];

const technologies = [
  {
    name: "HTML 5",
    icon: html,
  },
  {
    name: "CSS 3",
    icon: css,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "TypeScript",
    icon: typescript,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "NextJS",
    icon: nextjs,
  },
  {
    name: "Redux Toolkit",
    icon: redux,
  },
  {
    name: "Material UI",
    icon: mui,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },
  {
    name: "MongoDB",
    icon: mongodb,
  },
  {
    name: "git",
    icon: git,
  },
  {
    name: "api",
    icon: api,
  },
  {
    name: "python",
    icon: py,
  },
  {
    name: "C#",
    icon: csharp,
  },
  {
    name: "graphQL",
    icon: graphql,
  },
  {
    name: "Figma",
    icon: figma,
  },
  {
    name: "AWS",
    icon: aws,
  },
  {
    name: "MySQL",
    icon: mysql,
  },
  {
    name: "UiPath",
    icon: uipath,
  },
  {
    name: "ASP.NET",
    icon: aspnet,
  },
];

const experiences = [
  {
    title: "Full Stack Developer",
    company_name: "Mighty Games Group",
    icon: mbtlogo,
    iconBg: "#E6DEDD",
    date: "July 2023 - Jan 2024",
    points:
      "Mainly using Next.js 13, Tailwind CSS and TypeScript as coding skill set, design application and SPA webpage from scratch via using Figma prototype design platform. Writing unit testing in Jest, backend skill set as C# and AWS. Great in working collaboratively and individually, comfortable with fully remote working styles. Good at time management and working efficiently and smartly.",
  },
  {
    title: "Software Engineer Analyst",
    company_name: "Accenture Australia",
    icon: accenture,
    iconBg: "#E6DEDD",
    date: "June 2021 - July 2023",
    points:
      "Developing and maintaining web applications using React.js and other related technologies; collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products;multiple projects experience for different roles and responsibilities as developer, tester, deployment, and integration; participating in code reviews and providing constructive feedback to other developers.",
  },
  {
    title: "Software Developer Intern",
    company_name: "Lightmare Studios",
    icon: lightmare,
    iconBg: "#E6DEDD",
    date: "Aug 2020 - Oct 2020",
    points:
      "Using C#(Object-Oriented Programming) and Unity 2D platform; collaborating with team to do test and debug; code comprehension and resuable, working within real world.",
  },
  {
    title: "Master's in Computer Science",
    company_name: "Queensland University of Technology",
    icon: qut,
    iconBg: "#E6DEDD",
    date: "July 2017 - July 2019",
    points: "Algorithm & Machine Learning; C# & Blockchain",
  },
];

const testimonials = [
  {
    testimonial:
      "I thought it was impossible to make a website as beautiful as our product, but Rick proved me wrong.",
    name: "Sara Lee",
    designation: "CFO",
    company: "Acme Co",
    image: "https://randomuser.me/api/portraits/women/4.jpg",
  },
  {
    testimonial:
      "I've never met a web developer who truly cares about their clients' success like Rick does.",
    name: "Chris Brown",
    designation: "COO",
    company: "DEF Corp",
    image: "https://randomuser.me/api/portraits/men/5.jpg",
  },
  {
    testimonial:
      "After Rick optimized our website, our traffic increased by 50%. We can't thank them enough!",
    name: "Lisa Wang",
    designation: "CTO",
    company: "456 Enterprises",
    image: "https://randomuser.me/api/portraits/women/6.jpg",
  },
];

const projects = [
  {
    name: "CasExpress",
    category: "Frontend",
    description:
      "Fully responsive Ecommerce web application with Cart and Payment. ",
    tags: ["react", "nextJS", "stripe", "sanity"],
    image: casexpress,
    live_link: "https://casexpress.vercel.app",
    code_link: "https://github.com/Caspar2318/casexpress",
  },
  {
    name: "Collection",
    category: "Frontend",
    description: "Calendar, Kanban, Color-picker, Charts, Theme color, Tables.",
    tags: ["react", "syncfusion", "tailwind"],
    image: collection,
    live_link: "https://collection.cxcspace.com",
    code_link: "https://github.com/Caspar2318/collection-feature-dashboard",
  },
  {
    name: "X-Board",
    category: "Fullstack",
    description:
      "Fullstack real estate dashboard with CRUD functions coding in TypeScript.",
    tags: ["react", "mongodb", "mui", "auth"],

    image: xboard,
    live_link: "https://xboard.cxcspace.com",
    code_link: "https://github.com/Caspar2318/fullstack_CRUD_dashboard",
  },
  {
    name: "Youtube Clone",
    category: "Frontend",
    description: "Personal online video zone as Youtube clone with API usage.",
    tags: ["react", "rapidAPI"],
    image: youtube,
    live_link: "https://my-video-zone.cxcspace.com",
    code_link: "https://github.com/Caspar2318/youtube-clone-practice",
  },
  {
    name: "Shape Health",
    category: "Frontend",
    description: "Fitness guidance for body parts with videos recommendation.",
    tags: ["react", "rapidAPI", "mui"],
    image: fitness,
    live_link: "https://shape-health.cxcspace.com",
    code_link: "https://github.com/Caspar2318/shape-health",
  },
  {
    name: "ChatGPT!",
    category: "Frontend",
    description: "Personal ChatGPT via usage of OpenAI GPT-3 davinci-003.",
    tags: ["react", "vanillaJS", "openAI"],
    image: chatgpt,
    live_link: "https://chatgpt.cxcspace.com",
    code_link: "https://github.com/Caspar2318/chatGPT_App",
  },
  {
    name: "Job Search",
    category: "React Native",
    description:
      "First practice project for mobile app in React Native, Android open only.",
    tags: ["react-native", "Expo"],
    image: reactNative1,
    live_link:
      "https://expo.dev/@caspar_expo/react-native-job-finder?serviceType=classic&disstribution=expo-go",
    code_link: "https://github.com/Caspar2318/react-native-job-finder",
  },
  {
    name: "Crypto Search",
    category: "Frontend",
    description: "Checking on currently varified cryptocurrency information. ",
    tags: ["react", "RapidAPI", "redux", "antd"],
    image: cryptosearch,
    live_link: "https://cryptosearch.cxcspace.com",
    code_link: "https://github.com/Caspar2318/crypto-search",
  },
  {
    name: "Netflix?",
    category: "Fullstack",
    description:
      "Fullstack Netflix clone app with TypeScript, nextJS, OAuth, prisma etc.",
    tags: ["TS", "mongodb", "prisma", "OAuth"],

    image: netflix,
    live_link: "https://movie-world-322eqqwtv-caspar2318.vercel.app",
    code_link: "https://github.com/Caspar2318/netflix-clone",
  },
  {
    name: "3D portfolio",
    category: "3D Website",
    description: "Another personal portfolio website with 3D models applied.",
    tags: ["react", "TS", "threeJS"],

    image: portfolio,
    live_link: "https://3dportfolio.cxcspace.com/",
    code_link: "https://github.com/Caspar2318/3d-portfolio",
  },
  {
    name: "3D Desiner",
    category: "3D Website",
    description: "Customise your own t-shirt~!",
    tags: ["react", "threeJS", "nodeJS", "NextJS"],

    image: shirt,
    live_link: "https://3dshirt.cxcspace.com/",
    code_link: "https://github.com/Caspar2318/3d-model",
  },
  {
    name: "Airbnb!",
    category: "Fullstack",
    description:
      "Fullstack Airbnb clone app with multiple features to be implemented.",
    tags: ["TS", "mongodb", "prisma", "NextJS"],

    image: airbnb,
    live_link: "https://travel-trips-f2t3sme8q-caspar2318.vercel.app/",
    code_link: "https://github.com/Caspar2318/airbnb-clone",
  },
  {
    name: "Social meida",
    category: "Fullstack",
    description: "Fullstack social media to follow, like, comment and post.",
    tags: ["TS", "mongodb", "prisma", "NextJS"],

    image: twitter,
    live_link: "https://social-media-seven-sage.vercel.app/",
    code_link: "https://github.com/Caspar2318/twitter-clone",
  },
  {
    name: "Article Reader",
    category: "Frontend",
    description: "Boost reading efficiency with AI assisstance!",
    tags: ["Redux", "Vite", "tailwind"],

    image: summarizer,
    live_link: "https://readerai.cxcspace.com/",
    code_link: "https://github.com/Caspar2318/gpt4-summarizer",
  },
  {
    name: "NFT Game",
    category: "Web3",
    description: "Web 3 Game?!",
    tags: ["Web3", "Vite", "tailwind"],

    image: nftgame,
    live_link: "https://nftgame.cxcspace.com/",
    code_link: "https://github.com/Caspar2318/nft-game",
  },
];

export { technologies, experiences, testimonials, projects };
