import React, { useState } from "react";
import emailjs from "@emailjs/browser";

import { AppWrap, MotionWrap } from "../../wrapper";
import "./Contact.scss";
import { email, mobile } from "../../assets";

const Contact = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (form.name !== "" && form.email !== "" && form.message !== "") {
      emailjs
        .send(
          "service_5k4rrx3",
          "template_6p4tm28",
          {
            from_name: form.name,
            to_name: "Caspar",
            from_email: form.email,
            to_email: "caspar160423@gmail.com",
            message: form.message,
          },
          "P6TPOYLb2xQv7TLcc"
        )
        .then(
          () => {
            setLoading(false);
            setIsFormSubmitted(true);
            alert(
              "Thanks for reaching out, I'll back to you ASAP. Having a great day~"
            );

            setForm({
              name: "",
              email: "",
              message: "",
            });
            setIsFormSubmitted(false);
          },
          (error) => {
            setLoading(false);

            console.log(error);
            alert("Something went wrong. ");
          }
        );
    } else {
      alert("Please enter the related info.");
      setLoading(false);
    }
  };

  return (
    <>
      <h2 className="head-text">Let's have a chat</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={email} alt="email" />
          <p className="p-text">caspar160423@gmail.com</p>
        </div>
        <div className="app__footer-card">
          <img src={mobile} alt="phone" />
          <p className="p-text">+1 672 272 8097</p>
        </div>
      </div>
      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input
              className="p-text"
              type="text"
              placeholder="Your Name"
              name="name"
              value={form.name}
              onChange={handleChange}
            />
          </div>
          <div className="app__flex">
            <input
              className="p-text"
              type="email"
              placeholder="Your Email"
              name="email"
              value={form.email}
              onChange={handleChange}
            />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={form.message}
              name="message"
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="p-text" onClick={handleSubmit}>
            {!loading ? "Send Message" : "Sending..."}
          </button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">Thank you for getting in touch!</h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Contact, "app__footer"),
  "contact",
  "app__whitebg"
);
